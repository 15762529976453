import React from "react"
import { Link, graphql } from "gatsby"
import get from "lodash/get"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FormattedMessage } from "gatsby-plugin-react-intl"

import Seo from "../../components/seo"
import Layout from "../../components/layout"
import * as styles from "./service.scss"

class ServiceTemplate extends React.Component {
  render() {
    const service = get(this.props, "data.contentfulServiceBlog")


    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: node => {
          const assetId = node.data.target.sys.id
          const asset = service.body.references?.find(
            reference => reference.contentful_id === assetId
          )

          if (!asset || asset.__typename !== "ContentfulAsset") {
            return null
          }

          const { gatsbyImageData, description } = asset
          return (
            <div className="service-post-image">
              <GatsbyImage
                image={getImage(gatsbyImageData)}
                alt={description || ""}
              />
            </div>
          )
        },
      },
    }

    return (
      <Layout location={this.props.location}>
        <Seo
          title={service.menuItem}
          // image={`https:${post.image.resize.src}`}
          description={service.introText}
        />
        <div className="service-post">
          <div className="service-post-top"></div>

          <div className="service-post-intro">
            <div className="service-post-intro-title">
              <h1>{service.menuName}</h1>
            </div>
            <div className="service-post-intro-text">
              <p>{service.introText.introText}</p>
            </div>

            <div className="service-button">
            <Link to="/contact">
              <button className="button-main">
                <FormattedMessage id="servicePage.button" />
              </button>
              </Link>
            </div>
          </div>

          <div className="service-tiles">
            <div className="service-tiles-title">
              <h1>Key Benefits</h1>
            </div>
            {service.serviceTiles &&
              service.serviceTiles.length &&
              service.serviceTiles.map((tile, index) => (
                <div key={index} className="service-tile">
                  <div className="tile-right">
                    <img src={tile.image?.file.url} alt={tile.image?.title}></img>
                  </div>

                  <div className="tile-left">
                    <h3>{tile.title}</h3>
                    <p>{tile.description.description}</p>
                  </div>
                </div>
              ))}
          </div>

          <div className="service-body">
            {service.body?.raw &&
              renderRichText(service.body, options, {
                references: service.body.references,
              })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default ServiceTemplate

export const pageQuery = graphql`
  query ServiceBlogBySlug($slug: String!, $locale: String) {
    contentfulServiceBlog(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      slug
      menuName
      body {
        raw
      }
      introText {
        introText
      }
      serviceTiles {
        title
        description {
          description
        },
        image {
            file {
              url
            }
            title
          }
      }
    }
  }
`
